.selected-filter{
    border: 1px solid #385a64 !important;
    color: #385a64 !important;
    font-weight: 600 !important;
}

.date-filter-container{
    margin-top: 1rem !important;
    z-index: 1 !important;
    padding: 1rem !important;
    background: white !important;
    border-radius: 5px !important;
    width: auto;
}

.range-date{
    font-size: 1.1rem;
    font-weight: 600;
    color:#385a64 ;
    margin-left: 0.5rem;
}

.daterange-header{
    display: flex;
    gap: 1.5rem;
    align-items: center;
}
daterange-header-buttons{
    display: inline;
}
.datepicker-buttons{
    margin-top: 0.5rem;
}

@media screen and (max-width: 1200px) {
    .daterange-header{
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
    }
}


@media screen and (max-width: 750px) {
    .daterange-selected-dates{
        font-size: 0.8rem;
    }
    .range-date{
        font-size: 1rem;
    }
    .daterange-header-buttons{
        display: flex;
        justify-content: space-around;
    }
    .auto-range-button{
        font-size: 0.8rem !important;
    }
    .selected-filter{
        font-size: 0.8rem !important;
        padding: 0.25rem !important;

    }


}
