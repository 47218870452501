/* main dark teal #1a2e35
lighter shade: #385a64
accent cherry red #BC343E

*/

:root {
  --amplify-primary-color: #1a2e35;
  --amplify-primary-tint: #137bc0;
  --amplify-primary-shade: #137bc0;
  --container-align: flex-start;
  --amplify-secondary-tint: rgb(19, 123, 192, 1);
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.MuiDropzoneArea-root {
  height: 150px !important;
  min-height: 150px !important;
}

.MuiToolbar-root {
  flex-grow: 2;
}
